/**
 *
 *
 */
module.exports = {
    template: require('./project.template.html'),
    mixins: [require('../../mixins/pager')],
    created: function() {
        this.projectId = this.$route.params.projectId;
        this.loadProjectData();
    },
    ready: function() {
        this.$dispatch('root.change.navbar.color', 'white');
        this.carousel = $('#' + this.id);
        var self = this;
        $(document).keydown(function(event) {
            if (event.keyCode == 37) {
                self.carouselPrev();
            } else if (event.keyCode == 39) {
                self.carouselNext();
            }
        });
    },
    methods: {
        loadProjectData: function() {
            this.$http.get('/api/project/' + this.projectId, function(data) {
                this.project = data;
            });
        },
        carouselPrev: function() {
            if (this.carousel.find('.item.active').hasClass('first')) {
                this.$route.router.go({
                    name: 'projects'
                });
            } else {
                this.carousel.carousel('prev');
            }
        },
        carouselNext: function() {
            if (this.carousel.find('.item.active').hasClass('last')) {
                this.pageDirections.projects = 'left';
                this.$route.router.go({
                    name: 'projects'
                });
            } else {
                this.carousel.carousel('next');
            }
        }
    },
    data: function() {
        return {
            id: 'carousel-project',
            projectId: null,
            carousel: null,
            nextPage: 'office',
            prevPage: 'about',
            pageDirections: {
                'home': 'down',
                'about': 'down',
                'projects': 'right',
                'office': 'up',
                'press': 'up'
            },
            project: {}
        }
    }
}