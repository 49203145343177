/**
 *
 *
 */
module.exports = {
    template: require('./contact.template.html'),
    mixins: [require('../../mixins/pager')],
    created: function() {
        this.loadContactData();
    },
    ready: function() {
        var self = this;
        this.mapHeight();
        $(window).resize(function() {
            self.mapHeight();
        });
    },
    methods: {
        mapHeight: function() {
            if ($('#addressSmContainer').css('display') == "block") {
                var contactContainerHeight = $('.contact-container').height();
                var addressSmContainerHeight = $('#addressSmContainer').outerHeight(true);
                $('#mapContainer').css({
                    'height': contactContainerHeight - addressSmContainerHeight
                });
            } else {
                $('#mapContainer').css({
                    'height': '100%'
                });
            }
        },
        loadContactData: function() {
            this.$http.get('/api/contact/', function(data) {
                this.text = data.text.replace(/\r\n|\r|\n/g, "<br/>");
                this.links = data.links;
            });
        }
    },
    data: function() {
        return {
            nextPage: 'press',
            prevPage: 'office',
            text: '',
            links: [],
            pageDirections: {
                'home': 'down',
                'about': 'down',
                'projects': 'down',
                'office': 'down',
                'press': 'up'
            }
        }
    },
    components: {
        map: require('../../components/map')
    }
}