/**
 *  Load a Google map.
 *
 *  Example:
 *  <map class="my-class col-md-4" address="My address (optional)"></map>
 */
module.exports = {
    template: require('./map.template.html'),
    props: ['class', 'address'],
    ready: function() {
        if (typeof google !== 'undefined') {
            this.geocoder = new google.maps.Geocoder();
            this.setMapOptions(14, new google.maps.LatLng(-34.397, 150.644));
            this.map = new google.maps.Map(document.getElementById(this.id), this.mapOptions);
            this.geocode();

        }
    },
    data: function() {
        return {
            id: 'gmap-' + Math.floor(Math.random() * 1000),
            geocoder: false,
            mapOptions: {},
            map: false
        }
    },
    methods: {
        geocode: function(address) {
            if (address) {
                this.address = address;
            }
            if (this.geocoder && this.address) {
                var map = this.map;
                this.geocoder.geocode({
                    'address': this.address
                }, function(results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        map.setCenter(results[0].geometry.location);
                        var marker = new google.maps.Marker({
                            map: map,
                            icon: 'http://barrettdesign.com/img/icons/map.png',
                            position: results[0].geometry.location
                        });
                    } else {
                        alert('Geocode was not successful for the following reason: ' + status);
                    }
                });
            }
        },
        setMapOptions: function(zoom, latlng) {
            this.mapOptions = {
                zoom: zoom,
                center: latlng,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: false,
                streetViewControl: false,
                draggable: true,
                scrollwheel: false,
                disableDefaultUI: false,
                styles: [
                    {
                        "featureType": "administrative",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#444444"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 45
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#fbfbfb"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#d8d8d8"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    }
                ]
            };
        }
    }
}