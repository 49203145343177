/**
 *
 *
 */
module.exports = {
    methods: {
        goPage: function(event) {
            if ($(this.$el).css('top') == '0px') {
                var scrollable = $('.scrollable');
                var scrollableContent = $('.scrollable-content');
                if ($(event.target).hasClass("scrollable-content")) {
                    scrollableContent = $(event.target);
                    scrollable = scrollableContent.parents(".scrollable").first();
                } else if ($(event.target).parents(".scrollable-content").length) {
                    scrollableContent = $(event.target).parents(".scrollable-content").first();
                    scrollable = scrollableContent.parents(".scrollable").first();
                }
                //Can Scroll 
                var canPrevPage = true;
                var canNextPage = true;
                if (scrollable.length && ($(event.target).hasClass("scrollable-content") || $(event.target).parents(".scrollable-content").length)) {
                    canPrevPage = scrollable.scrollTop() <= 5;
                    var scrollTop = scrollable.scrollTop();
                    var scrollableHeight = scrollable.outerHeight(true);
                    var scrollableContentHeight = scrollableContent.outerHeight(true);
                    canNextPage = (scrollableContentHeight - scrollableHeight - 25) <= scrollTop;
                }

                if (event.type == "touchmove") {
                    var currentY = event.touches[0].clientY;
                    if (this.prevPage && canPrevPage && this.lastY && currentY > this.lastY) {
                        this.$route.router.go({
                            name: this.prevPage
                        });
                    } else if (this.nextPage && canNextPage && this.lastY && currentY < this.lastY) {
                        this.$route.router.go({
                            name: this.nextPage
                        });
                    }
                    this.lastY = currentY;
                } else {
                    var delta = event.wheelDelta ? event.wheelDelta / 60 : -event.detail / 2;
                    if (this.nextPage && canNextPage && delta < 0) {
                        this.$route.router.go({
                            name: this.nextPage
                        });
                    } else if (this.prevPage && canPrevPage && delta > 0) {
                        this.$route.router.go({
                            name: this.prevPage
                        });
                    }
                }
            }
        }
    },
    data: function() {
        return {
            lastY: null,
            lastWheelY: null,
            nextPage: null,
            prevPage: null,
            pageDirections: {}
        }
    },
    route: {
        canDeactivate: function(transition) {
            var direction = this.pageDirections[transition.to.name] ? this.pageDirections[transition.to.name] : 'up';
            this.$dispatch('change.transition', direction);
            this.$dispatch('root.change.navbar.color', 'black');
            setTimeout(function() {
                transition.next();
            }, 10);
        }
    }
}