/**
 *
 *
 */
module.exports = {
    template: require('./office.template.html'),
    mixins: [require('../../mixins/pager')],
    created: function() {
        this.$dispatch('root.change.navbar.color', 'black');
        this.loadEmployeesData();
    },
    methods: {
        loadEmployeesData: function() {
            this.$http.get('/api/staff/', function(data) {
                this.employees = data;
            });
        },
    },
    data: function() {
        return {
            nextPage: 'contact',
            prevPage: 'projects',
            pageDirections: {
                'home': 'down',
                'about': 'down',
                'projects': 'down',
                'employee': 'left',
                'contact': 'up',
                'press': 'up'
            },
            employees: []
        }
    }
}