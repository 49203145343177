/**
 *
 *
 */
module.exports = {
    template: require('./projects.template.html'),
    mixins: [require('../../mixins/pager')],
    created: function() {
        this.loadProjectsData();
        this.$dispatch('root.change.navbar.color', 'black');
    },
    methods: {
        loadProjectsData: function() {
            this.$http.get('/api/projects/public', function(data) {
                this.projects = data;
            });
        },
    },
    data: function() {
        return {
            nextPage: 'office',
            prevPage: 'about',
            pageDirections: {
                'home': 'down',
                'about': 'down',
                'office': 'up',
                'project': 'left',
                'press': 'up'
            },
            projects: []
        }
    }
}