/**
 *
 *
 */
module.exports = {
    template: require('./navbar.template.html'),
    created: function() {
        if (!$('body').data('hasHome')) {
            this.hasHomeLink = false;
        }
    },
    props: {
        hideLinks: {
            default: false
        },
        websiteLinks: {
            default: 'public'
        },
        showSection: {
            default: false
        },
    },
    events: {
        'change.navbar.color': function(color) {
            this.color = color;
        },
        'navbar.showSection': function(section) {
            this.section = section;
            this.showSection = true;
        },
        'navbar.subSection': function(subSection) {
            this.subSection = subSection;
        },
        'navbar.hideSection': function() {
            this.section = '';
            this.subSection = '';
            this.showSection = false;
        },
        'navbar.showLinks': function() {
            this.hideLinks = false;
        },
        'navbar.hideLinks': function() {
            this.hideLinks = true;
        }
    },
    data: function() {
        return {
            color: 'black',
            section: '',
            subSection: '',
            hasHomeLink: true
        }
    }
}