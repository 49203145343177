/**
 *
 *
 */
module.exports = {
    css: false,
    enter: function(el, done) {
        if ($('#transitionDirection').hasClass('up')) {
            $(el)
                .css('top', $(window).height())
                .animate({
                    top: 0
                }, 1000, done);
        } else if ($('#transitionDirection').hasClass('down')) {
            $(el)
                .css('top', -$(window).height())
                .animate({
                    top: 0
                }, 1000, done);
        } else if ($('#transitionDirection').hasClass('right')) {
            $(el)
                .css('top', 0)
                .css('left', -$(window).width())
                .animate({
                    left: 0
                }, 1000, done);
        } else if ($('#transitionDirection').hasClass('left')) {
            $(el)
                .css('top', 0)
                .css('left', $(window).width())
                .animate({
                    left: 0
                }, 1000, done);
        }
    },
    enterCancelled: function(el) {
        $(el).stop()
    },
    leave: function(el, done) {
        if ($('#transitionDirection').hasClass('up')) {
            $(el).animate({
                top: -$(window).height()
            }, 1000, done);
        } else if ($('#transitionDirection').hasClass('down')) {
            $(el)
                .animate({
                    top: $(window).height()
                }, 1000, done);
        } else if ($('#transitionDirection').hasClass('right')) {
            $(el)
                .animate({
                    left: $(window).width()
                }, 1000, done);
        } else if ($('#transitionDirection').hasClass('left')) {
            $(el)
                .animate({
                    left: -$(window).width()
                }, 1000, done);
        }
    },
    leaveCancelled: function(el) {
        $(el).stop()
    }
}