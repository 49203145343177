/**
 *
 *
 */
module.exports = {
    template: require('./press.template.html'),
    mixins: [require('../../mixins/pager')],
    created: function() {
        this.$dispatch('root.change.navbar.color', 'black');
        this.loadPressData();
    },
    data: function() {
        return {
            nextPage: null,
            prevPage: 'contact',
            pageDirections: {
                'home': 'down',
                'about': 'down',
                'projects': 'down',
                'office': 'down',
                'contact': 'down'
            },
            pressItems: []
        }
    },
    methods: {
        loadPressData: function() {
            this.$http.get('/api/press/', function(data) {
                this.pressItems = data;
            });
        },
    },
}