var Vue = require('vue');
Vue.use(require('vue-resource'));
var VueRouter = require('vue-router');
Vue.use(VueRouter);

var home = Vue.extend(require('./home/home'));
var about = Vue.extend(require('./about/about'));
var projects = Vue.extend(require('./projects/projects'));
var project = Vue.extend(require('./projects/project'));
var office = Vue.extend(require('./office/office'));
var employee = Vue.extend(require('./office/employee'));
var contact = Vue.extend(require('./contact/contact'));
var press = Vue.extend(require('./press/press'));

Vue.transition('slide', require('../transitions/slide'));
var app = Vue.extend({
    el: '#barrett-public',
    props: ['langname'],
    data: function() {
        return {
            transitionEffect: 'up'
        }
    },
    ready: function() {
        var self = this;
        self.initBootstrap();
        document.addEventListener("touchstart", function() {}, false);
        setTimeout(function() {
            $('.navbar-brand').removeClass('logo-animation');
        }, 2000);
    },
    events: {
        'change.transition': function(type) {
            this.$data.transitionEffect = type;
        },
        'root.change.navbar.color': function(color) {
            this.$broadcast('change.navbar.color', color);
        }
    },
    components: {
        navbar: require('../components/navbar')
    },
    methods: {
        initBootstrap: function() {
            $('[data-toggle="tooltip"]').tooltip();
            $('[data-toggle="popover"]').popover();
            $('.navbar-toggle').click(function() {
                $('#mainNavbar').toggleClass("in");
            });
            $('.navbar-nav li a, .navbar-brand').click(function() {
                if ($('.navbar-main').hasClass('in')) {
                    $('.navbar-main').collapse('toggle');
                    $('#mainNavbar').toggleClass("in");
                }
            });
        }
    }
});

var router = new VueRouter({
    history: true
});

router.map({
    '/': {
        name: 'home',
        component: home
    },
    '/about': {
        name: 'about',
        component: about
    },
    '/projects': {
        component: {
            template: '<router-view></router-view>'
        },
        subRoutes: {
            '/': {
                name: 'projects',
                component: projects
            },
            '/:projectId': {
                name: 'project',
                component: project
            }
        }
    },
    '/office': {
        component: {
            template: '<router-view></router-view>'
        },
        subRoutes: {
            '/': {
                name: 'office',
                component: office
            },
            '/:employeeId': {
                name: 'employee',
                component: employee
            }
        }
    },
    '/contact': {
        name: 'contact',
        component: contact
    },
    '/press': {
        name: 'press',
        component: press
    }
});
router.start(app, '#barrett-public');