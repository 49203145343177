/**
 *
 *
 */
module.exports = {
    template: require('./home.template.html'),
    mixins: [require('../../mixins/pager')],
    created: function() {
        this.loadProjectData();  
    },
    methods: {
        loadProjectData: function() {
            this.$http.get('/api/landing/', function(data) {
                this.landing = data;
            });
        }
    },
    data: function() {
        return {
            nextPage: 'about',
            prevPage: null,
            pageDirections: {
                'about': 'up',
                'projects': 'up',
                'office': 'up',
                'contact': 'up',
                'press': 'up'
            },
            leftBg: 'none',
            activeColor: '#FA0000',
            landing: {}
        }
    }
}