/**
 *
 *
 */
module.exports = {
    template: require('./about.template.html'),
    mixins: [require('../../mixins/pager')],
    created: function() {
        if (!$('body').data('hasHome')) {
            this.prevPage = null;
        }
        this.loadAboutData();
    },
    ready: function() {
        var self = this;
        $('#carousel-about').on('slide.bs.carousel', function(event) {
            self.changeColor(event);
        });
    },
    methods: {
        changeColor: function(event) {
            this.$data.carouselColor = $(event.relatedTarget).hasClass('white') ? 'white' : 'black';
            this.$dispatch('root.change.navbar.color', this.$data.carouselColor);
        },
        loadAboutData: function() {
            this.$http.get('/api/simplecollection/about', function(data) {
                this.pages = data;
            });
        }
    },
    data: function() {
        return {
            carouselColor: 'black',
            nextPage: 'projects',
            prevPage: 'home',
            pages: [],
            pageDirections: {
                'home': 'down',
                'projects': 'up',
                'office': 'up',
                'contact': 'up',
                'press': 'up'
            }
        }
    }

}