/**
 *
 *
 */
module.exports = {
    template: require('./employee.template.html'),
    mixins: [require('../../mixins/pager')],
    created: function() {
        this.loadEmployeesData();
    },
    ready: function() {
        this.carousel = $('#' + this.id);
        this.employeeId = this.$route.params.employeeId;
        var self = this;
        $(document).keydown(function(event) {
            if (event.keyCode == 37) {
                self.carouselPrev();
            } else if (event.keyCode == 39) {
                self.carouselNext();
            }
        });
        $(window).resize(function() {
            self.scrollableHeight();
        });
        var interval = setInterval(scrollableHeightInterval, 50);
        function scrollableHeightInterval() {
            if ($('.scrollable').length > 0 && $('.scrollable').length == self.employees.length) {
                self.scrollableHeight();
                clearInterval(interval);
            }
        }
    },
    methods: {
        loadEmployeesData: function() {
            this.$http.get('/api/staff/', function(data) {
                this.employees = data;
            });
        },
        scrollableHeight: function() {
            var windowHeight = $(window).height();
            var marginTop = parseInt($('.employee-container').css('margin-top'));
            var marginBottom = parseInt($('.employee-container').css('margin-bottom'));
            $('.scrollable').css({
                'max-height': windowHeight - marginTop - marginBottom
            });
        },
        carouselPrev: function() {
            if (this.carousel.find('.item.active').hasClass('first')) {
                this.$route.router.go({
                    name: 'office'
                });
            } else {
                this.carousel.carousel('prev');
            }
        },
        carouselNext: function() {
            if (this.carousel.find('.item.active').hasClass('last')) {
                this.pageDirections.office = 'left';
                this.$route.router.go({
                    name: 'office'
                });
            } else {
                this.carousel.carousel('next');
            }
        }
    },
    data: function() {
        return {
            id: 'carousel-employee',
            employeeId: null,
            carousel: null,
            nextPage: 'contact',
            prevPage: 'projects',
            pageDirections: {
                'home': 'down',
                'about': 'down',
                'projects': 'down',
                'office': 'right',
                'contact': 'up',
                'press': 'up'
            },
            employees: []
        }
    }
}